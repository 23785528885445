/**
 * Custom form elements
 *    - Checkbox
 *    - Radios
 */

@import '../Common/Variables';
@import '../Common/Mixins';

//
// CHECKBOX AND RADIOS
//

.c-checkbox,
.c-radio {
    margin-right: 4px;

    input {
        opacity: 0;
        position: absolute;
        margin-left: 0 !important;
    }

    // the element that replaces the input
    span {
        position: relative;
        display: inline-block;
        vertical-align: top;
        // margin-left: -20px;
        width: 20px; height: 20px;
        border-radius: 2px;
        border: 1px solid rgba($gray-base,.36);
        margin-right: 5px;
        text-align: center;
        &:before {
            margin-left: 0; // fa align trick
        }
    }

    &:hover span {
        border-color: $chkradio-fg-color;
    }

}

// rounded (default for radios)
.c-radio span,
.c-checkbox-rounded span {
    border-radius: 500px;
}

// Checked state for checkbox (uses icon font)
.c-checkbox {
    span:before {
        //content: icon fonts already uses this pseudo
        position: absolute;
        top: 0; left: 0;  right: 0; bottom: 0;
        opacity: 0;
        text-align: center !important;
        font-size: 12px;
        line-height: 18px;
        vertical-align: middle;
    }

    input[type=checkbox]:checked + span:before {
        color: #fff;
        opacity: 1;
        transition: color .3s ease-out;
    }

    input[type=checkbox]:checked + span {
        border-color: $chkradio-fg-color;
        background-color: $chkradio-fg-color;
    }

    // Disable state
    input[type=checkbox]:disabled + span {
        border-color: $chkradio-bg-color !important;
        background-color: $chkradio-bg-color !important;
        cursor: not-allowed;
    }
}

// Checked state for radios (uses css only)
.c-radio {
    span:before {
        $sz: 10px;
        position: absolute;
        content: "";
        width: $sz; height: $sz;
        top: 50%; left: 50%;
        margin-top: -($sz/2);
        margin-left: -($sz/2);
        border-radius: 500px;
        opacity: 0;
    }

    input[type=radio]:checked + span:before{
        color: #fff;
        opacity: 1;
        transition: color .3s ease-out;
    }

    input[type=radio]:checked + span {
        border-color: $chkradio-fg-color;
        background-color: $chkradio-bg-color;
        &:before{
            background-color: $chkradio-fg-color;
        }
    }

    // Disable state
    input[type=radio]:disabled + span{
        border-color: $chkradio-bg-color !important;
        cursor: not-allowed;
        &:before {
            background-color: $chkradio-bg-color;
        }
    }
}
