/**
 * Spinners (Loader.CSS)
 */

@import '../Common/Variables';
@import '../Common/Mixins';

// Loader.CSS
// -----------------------------------

.loader-primary {

    $loader-color: $brand-primary;

    // Global background override
    .ball-pulse > div,
    .ball-grid-pulse > div,
    .square-spin > div,
    .ball-pulse-rise > div,
    .ball-rotate > div,
    .ball-rotate > div:before, .ball-rotate > div:after,
    .cube-transition > div,
    .ball-zig-zag > div,
    .ball-zig-zag-deflect > div,
    .ball-triangle-path > div,
    .ball-scale > div,
    .line-scale > div,
    .line-scale-party > div,
    .ball-scale-multiple > div,
    .ball-pulse-sync > div,
    .ball-beat > div,
    .line-scale-pulse-out > div,
    .line-scale-pulse-out-rapid > div,
    .ball-spin-fade-loader > div,
    .line-spin-fade-loader > div,
    .triangle-skew-spin > div,
    .ball-grid-beat > div,
    .semi-circle-spin > div {
        background-color: $loader-color;
    }

    // custom override
    .ball-clip-rotate-multiple {
        width: 1px;
        > div {
            border-color: $loader-color transparent $loader-color transparent;
            &:last-child {
                border-color: transparent  $loader-color transparent $loader-color;
            }
        }
    }
    .ball-clip-rotate > div {
        border-color: $loader-color;
        border-bottom-color: transparent;
    }
    .ball-clip-rotate-pulse > div {
        &:first-child {
            background-color: $loader-color;
            top: 5px;
            left: -8px;
        }
        &:last-child {
            border-color: $loader-color transparent $loader-color transparent;
        }
    }
    .square-spin > div,
    .ball-scale-ripple > div,
    .ball-scale-ripple-multiple > div {
        border-color: $loader-color;
    }
    .pacman > div:first-of-type {
        border-top-color: $loader-color;
        border-left-color: $loader-color;
        border-bottom-color: $loader-color;
    }
    .pacman > div:nth-child(2) {
        border-top-color: $loader-color;
        border-left-color: $loader-color;
        border-bottom-color: $loader-color;
    }
    .pacman > div:nth-child(3), .pacman > div:nth-child(4), .pacman > div:nth-child(5), .pacman > div:nth-child(6) {
        background-color: $loader-color;
    }
}

.loader {
    display: inline-block;
}

///////

.loader-demo {
    padding: 20px 0;
    height: 120px;
    text-align: center;
    > .loader-inner {
        display: inline-block;
    }
}
