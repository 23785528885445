/**
 * Ripple
 */

@import '../Common/Variables';
@import '../Common/Mixins';

.ripple {
    position: relative;
    overflow: hidden;
    > .md-ripple {
        display: block;
        position: absolute;
        top: 50%; left: 50%; // ripple autocenter (requires js)
        bottom: 0; right: 0;
        overflow: hidden;
        pointer-events: none;
        background-color: rgba(0,0,0,0.06);
        border-radius: 50%;
        transform: scale(0.0001, 0.0001);
        opacity: 1;
        width: 0; height: 0;
        &.md-ripple-animate {
            -webkit-transition: -webkit-transform .5s cubic-bezier(0,0,.2,1), opacity .6s cubic-bezier(0,0,.2,1);
            transition: transform .5s cubic-bezier(0,0,.2,1), opacity .6s cubic-bezier(0,0,.2,1);
            opacity: 0;
            transform: scale(2);
        }
    }
    &.btn-oval,
    &.btn-pill-left,
    &.btn-pill-right,
    &.btn-flat-icon,
    &.btn-circle {
        > .md-ripple {
            &.md-ripple-animate {
                transform: scale(.99);
            }
        }
    }
}
