/**
 * Modals
 */

@import '../Common/Variables';
@import '../Common/Mixins';

.modal-header,
.modal-footer {
    border-color: rgba($gray-base, .12);
}

// Modal Commons
.modal.modal-right,
.modal.modal-left,
.modal.modal-bottom,
.modal.modal-top {
    .modal-dialog {
        position: absolute;
        margin: 0;
        max-width: none;
        transition-transform: .3s cubic-bezier(0.25,0.8,0.25,1);
        > .modal-content {
            border-radius: 0;
            border: 0;
        }
    }

    &.fade.show {
        .modal-dialog {
            transform: translate(0, 0);
        }
    }
}

// animate only if animation enabled
.modal.fade.modal-right,
.modal.fade.modal-left,
.modal.fade.modal-bottom,
.modal.fade.modal-top {
    .modal-dialog {
        transition-transform: .3s cubic-bezier(0.25,0.8,0.25,1);
    }
}

// Modal Right
.modal.modal-right {
    .modal-dialog {
        right: 0;
        bottom: 0;
        top: 0;
        width: 240px;
        > .modal-content {
            min-height: 100%;
        }
    }

    &.modal-auto-size .modal-dialog {
        width: 80%;
        @media #{$min-tablet} {
            width: 50%;
        }
    }

    &.fade {
        .modal-dialog {
            transform: translate(100%, 0);
        }
    }
}
// Modal Left
.modal.modal-left {
    .modal-dialog {
        left: 0;
        bottom: 0;
        top: 0;
        width: 240px;
        > .modal-content {
            min-height: 100%;
        }
    }

    &.modal-auto-size .modal-dialog {
        width: 80%;
        @media #{$min-tablet} {
            width: 50%;
        }
    }

    &.fade {
        .modal-dialog {
            transform: translate(-100%, 0);
        }
    }
}
// Modal Top
.modal.modal-top {
    .modal-dialog {
        right: 0;
        left: 0;
        top: 0;
        width: 100%;
        > .modal-content {
            width: 100%;
        }
    }

    &.fade {
        .modal-dialog {
            transform: translate(0, -100%);
        }
    }
}
// Modal Bottom
.modal.modal-bottom {
    .modal-dialog {
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        > .modal-content {
            width: 100%;
        }
    }

    &.fade {
        .modal-dialog {
            transform: translate(0, 100%);
        }
    }
}

.modal-content {
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.14), 0 0 2px 2px rgba(0, 0, 0, 0.098), 0 0 5px 1px rgba(0, 0, 0, 0.084);
}

.modal-backdrop {
    opacity: 0;
    will-change: opacity;
    &.show {
        transition: opacity .28s ease-in-out;
    }
}
// lighter backdrop color
.modal-backdrop-light .modal-backdrop {
    background-color: #fff;
    &.show {
        opacity: .9;
    }
}
// softer backdrop color by default
.modal-backdrop-soft  .modal-backdrop {
    &.show {
        opacity: .3;
    }
}

// fix modal backdrop to work with bootstrap ui
// standard BS js uses a calculated height
.modal-backdrop {
    position: fixed;
    bottom: 0;
}
