/**
 * Dropdowns Extras
 */

@import '../Common/Variables';
@import '../Common/Mixins';

// place components with more space
.dropdown-lg > .dropdown-menu {
    min-width: 200px;
}

// Allows to mix list group inside dropdowns
.dropdown-list {

    > .dropdown-menu {
        padding: 0;
        min-width: 220px;
    }

    .list-group {
        margin: 0;
    }

    .list-group-item {
        border-radius: 0;
        border-left: 0;
        border-right: 0;
        &:first-child {
            border-top: 0;
        }
        &:last-child {
            border-bottom: 0;
        }
    }
}

// Labels inside dropdowns
.dropdown {
    > a {
        position: relative;
        > .label, > .tag {
            position: absolute;
            top: 0;
            right: 0;
            padding: 2px 5px;
            @media #{$min-tablet} {
                top: 10px;
            }
        }
    }
}

// Dropdown header
.dropdown-menu-header {
    padding: 10px 15px;
    background-color: #fafafa;
    border-bottom: 1px solid #ddd;
}
